// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hnVseFrjhpYzH317CWPt{display:flex;align-items:center;column-gap:15px}.xBjHaCvXpytFroOTCAdX{display:table;border-spacing:0 8px;width:100%;row-gap:4px;font-family:inherit}.EBtUyiC76iZgLZO70T5T{display:table-row;width:100%;padding-bottom:4px}.EBtUyiC76iZgLZO70T5T:last-child{padding-bottom:0}`, "",{"version":3,"sources":["webpack://./src/rating-summary/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,eAAA,CAEF,sBACE,aAAA,CACA,oBAAA,CACA,UAAA,CACA,WAAA,CACA,mBAAA,CAEF,sBACE,iBAAA,CACA,UAAA,CACA,kBAAA,CAEF,iCACE,gBAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  column-gap: 15px;\n}\n.ratingsWrapper {\n  display: table;\n  border-spacing: 0 8px;\n  width: 100%;\n  row-gap: 4px;\n  font-family: inherit;\n}\n.ratingWrapper {\n  display: table-row;\n  width: 100%;\n  padding-bottom: 4px;\n}\n.ratingWrapper:last-child {\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `hnVseFrjhpYzH317CWPt`,
	"ratingsWrapper": `xBjHaCvXpytFroOTCAdX`,
	"ratingWrapper": `EBtUyiC76iZgLZO70T5T`
};
export default ___CSS_LOADER_EXPORT___;
