// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oNpxS5P9tectifMTMw0S{display:flex;flex-direction:column;align-items:center;justify-content:center;row-gap:4px}.zO_sav5ObG8srDRDKZ94{font-size:36px;font-weight:600;margin:0}.whsS67xMLmkYLLrkeaI7{display:flex;flex-direction:row;column-gap:4px}.JH7Di2rVJITJQRjN2Qxg{display:flex;flex-direction:row;justify-content:center;color:#1d1d1d;font-size:12px;min-width:104px;padding-top:4px;column-gap:4px}.Q3SVIzX3noP9rBdEy4l4{width:18px;height:18px}`, "",{"version":3,"sources":["webpack://./src/rating-average/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,QAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,cAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CAEF,sBACE,UAAA,CACA,WAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  row-gap: 4px;\n}\n.averageRatingValue {\n  font-size: 36px;\n  font-weight: 600;\n  margin: 0;\n}\n.iconsWrapper {\n  display: flex;\n  flex-direction: row;\n  column-gap: 4px;\n}\n.subTextContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  color: #1D1D1D;\n  font-size: 12px;\n  min-width: 104px;\n  padding-top: 4px;\n  column-gap: 4px;\n}\n.starImage {\n  width: 18px;\n  height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `oNpxS5P9tectifMTMw0S`,
	"averageRatingValue": `zO_sav5ObG8srDRDKZ94`,
	"iconsWrapper": `whsS67xMLmkYLLrkeaI7`,
	"subTextContainer": `JH7Di2rVJITJQRjN2Qxg`,
	"starImage": `Q3SVIzX3noP9rBdEy4l4`
};
export default ___CSS_LOADER_EXPORT___;
