// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Kz24wLXscDomnbkEljUb{display:table-cell;padding-right:8px;white-space:nowrap;vertical-align:middle;font-size:12px;font-weight:500}.KmV2vz5lSJwT26AOidds{display:inline-block;vertical-align:middle;margin-right:4px;width:13px;height:13px}`, "",{"version":3,"sources":["webpack://./src/rating-label/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,qBAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,oBAAA,CACA,qBAAA,CACA,gBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".label {\n  display: table-cell;\n  padding-right: 8px;\n  white-space: nowrap;\n  vertical-align: middle;\n  font-size: 12px;\n  font-weight: 500;\n}\n\n.starImage {\n  display: inline-block;\n  vertical-align: middle;\n  margin-right: 4px;\n  width: 13px;\n  height: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `Kz24wLXscDomnbkEljUb`,
	"starImage": `KmV2vz5lSJwT26AOidds`
};
export default ___CSS_LOADER_EXPORT___;
